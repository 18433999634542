<template src="./job_detail.html"></template>

<script scoped>
import { Component, Prop, Vue } from "vue-property-decorator";
import $ from "jquery";

window.$ = $;
import "bootstrap/dist/js/bootstrap.bundle";
window.jQuery = $;
import "../../assets/styles/dropdown.scss";

import VueCarousel from "vue-carousel";
Vue.use(VueCarousel);

import searchBG from "@/components/searchBG.vue"; // @ is an alias to /src
import EventService from "@/services/EventService.js";

const jobBanners = {
  "head-office": "/static/assets/jobs/ho_small.jpg",
  dc: "/static/assets/jobs/dc_small.jpg",
  store: "/static/assets/jobs/store_small.jpg",
};

export default {
  components: {
    searchBG,
  },
  data() {
    return {
      mainImg: require(`@/assets/images/icon/Job_banner.png`),
      filter: 0,
      dfilter: 0,
      success: false,
      selected: 1,
      job: {},
      isLoading: true,
      fullPage: true,
      listAction: [
        { name: "Clear All", code: 1 },
        { name: "Apply", code: 2 },
      ],
      loader: null,
      url: window.location.href,
    };
  },
  computed: {
    getBanner() {
      const banner = jobBanners[this.job.area_slug];
      if (banner) return banner;
      return "/static/assets/jobs/default_small.jpg";
    },
    salary_display() {
      let text = "";
      if (this.job.salary_min && this.job.salary_max) {
        text = `${this.job.salary_min} - ${this.job.salary_max}`;
      } else if (this.job.salary_min) {
        text = this.job.salary_min;
      } else if (this.job.salary_max) {
        text = this.job.salary_max;
      }

      return text;
    },
    source() {
      return this.$store.getters.getSource["job-detail"];
    },
    sourceLink() {
      return this.$store.getters.getSource["link"];
    },
  },
  methods: {
    displayJobName(job) {
      if (this.$store.state.lang === "TH") {
        return job.custom_fields?.positionnameth
          ? job.custom_fields.positionnameth
          : job.position_name;
      }
      return job.position_name;
    },
    displayOrganizeName(name) {
      switch (name) {
        case "Head Office":
          return this.$store.state.lang === "EN"
            ? "Central Office Nawamintr"
            : "สำนักงานส่วนกลาง นวมินทร์";
        case "Store":
          return this.$store.state.lang === "EN" ? name : "สาขา";
        case "DC":
          return this.$store.state.lang === "EN" ? name : "ศูนย์กระจายสินค้า";
      }

      return "";
    },
    setDetail(val) {
      if (!val) return (this.dfilter = 0);
      if (window.innerWidth > 994) {
        // setTimeout(() => {
        this.dfilter = this.filter;
        // }, 1000);
      } else {
        this.dfilter = this.filter;
      }
    },
    clickMore() {
      $(".list-BG").addClass("h-auto");
      $(".more-BG").remove();
    },
  },
  created() {
    const loader = this.$loading.show();
    EventService.getJobById(this.$route.params.id)
      .then((response) => {
        console.log(response.data);
        this.job = response.data;
        this.isLoading = false;
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        loader.hide();
      });
  },
  mounted() {},
};
</script>

<style src="./job_detail.scss" scoped lang="scss"></style>
