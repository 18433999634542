<template src="./job_landing.html"></template>

<script>
import { Component, Prop, Vue } from "vue-property-decorator";
import $ from "jquery";
window.$ = $;
window.jQuery = $;
import "bootstrap/dist/js/bootstrap.bundle";

import VueCarousel from "vue-carousel";
Vue.use(VueCarousel);

import searchBG from "@/components/searchBG.vue"; // @ is an alias to /src

// @Component({
//   components: {
//     searchBG,
//   },
// })
export default {
  components: {
    searchBG,
  },
  data() {
    return {
      page: 1,
      slides: [
        {
          image: "/static/assets/jobs/banner_1.jpg",
          title: "Head Office",
        },
        { image: "/static/assets/jobs/banner_2.jpg", title: "Store" },
        { image: "/static/assets/jobs/banner_3.jpg", title: "DC" },
      ],
    };
  },
  methods: {
    displayOrganizeName(name) {
      switch (name) {
        case "Head Office":
          return this.$store.state.lang === "EN"
            ? "Central Office Nawamintr"
            : "สำนักงานส่วนกลาง นวมินทร์";
        case "Store":
          return this.$store.state.lang === "EN" ? name : "สาขา";
        case "DC":
          return this.$store.state.lang === "EN" ? name : "ศูนย์กระจายสินค้า";
      }

      return "";
    },
    displayOrganizeDescription(name) {
      switch (name) {
        case "Head Office":
          return this.source["head-office"]["description"];
        case "Store":
          return this.source["store"]["description"];
        case "DC":
          return this.source["dc"]["description"];
      }

      return "";
    },
  },
  computed: {
    source() {
      return this.$store.getters.getSource["job-detail"];
    },
  },
};
</script>
<style src="./job_landing.scss" scoped lang="scss"></style>
